import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { appState } from 'src/app';
import { selectConfig } from '../../store/config/selector/config.selectors';
import { HelpersService } from '../helpers/helpers.service';
import { QueryService } from '../query/query.service';
import { selectHelpers } from '../../store/helpers/selector/helpers.selectors';
import { PaymentMethods } from '../../interfaces/interfaces';
import { GeoCurrency, ResponseGeoCurrency } from '../../interfaces/interfaces';

@Injectable({
  providedIn: 'root',
})
export class KeystoneService implements OnDestroy {
  url: string = 'https://welle.homes/api';
  adminUrl: string = 'https://welle.homes/admin/api';
  $unsubus: Subject<boolean> = new Subject();
  Config$: Observable<any> = this.store.select(selectConfig);
  helpers$: Observable<any> = this.store.select(selectHelpers);
  country: string = 'lvl'; // mocked until we fix issue with country from config
  lang: any;

  constructor(
    private store: Store<appState>,
    private helper: HelpersService,
    private http: HttpClient,
    private query: QueryService
  ) {
    this.store
      .select('config')
      .pipe(takeUntil(this.$unsubus))
      .subscribe((res: any) => {
        if (res?.config?.env && res?.config?.country) {
          this.country = res.config.country;
        }
      });
    this.helpers$.pipe(takeUntil(this.$unsubus)).subscribe((res: any) => {
      if (res.isLoaded) {
        this.lang = res.currentLanguage;
      }
    });
  }
  getBanner(slug: string) {
    const isAuth$ = from(this.Config$);
    if (!this.lang) this.lang = 'en';
    return isAuth$.pipe(
      switchMap((result: any) => {
        result = result?.loggedIn == 1 ? true : false;
        return this.http.get(`${this.url}/banners/${slug}/${this.country}/${this.lang}/${result}`);
      })
    );
  }
  getAllTermsPages() {
    return this.http.post(this.adminUrl, this.query.getAllTermsPages()).pipe(
      map((response: any) => {
        const result: any = [];
        response.allTermsPages.forEach((element: any) => {
          result.push(this.helper.mapKeystoneFields(element));
        });
        return result.sort((a: any, b: any) => b.version - a.version);
      })
    );
  }
  getBannerPage(slug: string) {
    if (!this.lang) this.lang = 'en';
    if (slug === 'providers-banners-mobile' || slug === 'providers-banners-desktop') this.lang = 'en';

    return this.http.post(this.adminUrl, this.query.getBannerPage(this.lang, slug)).pipe(
      map((response: any) => {
        const result: any = [];
        response.allBannerPages.forEach((element: any) => {
          result.push(this.helper.mapKeystoneFields(element));
        });
        return result;
      })
    );
  }
  getAllPromotions() {
    if (!this.lang) this.lang = 'en';
    return this.http.post(this.adminUrl, this.query.getAllPromotions(this.lang)).pipe(
      map((response: any) => {
        const result: any = [];
        response.allPromotions.forEach((element: any) => {
          result.push(this.helper.mapKeystoneFields(element));
        });
        return result;
      })
    );
  }
  getPromotionBySlug(slug: string) {
    if (!this.lang) this.lang = 'en';
    return this.http.post(this.adminUrl, this.query.getPromotionBySlug(slug, this.lang)).pipe(
      map((response: any) => {
        const result: any = [];
        response.allPromotions.forEach((element: any) => {
          result.push(this.helper.mapKeystoneFields(element));
        });
        return result;
      })
    );
  }
  getPage(slug: string) {
    const lang = this.checkDocumentSlug(slug);
    return this.http.post(this.adminUrl, this.query.getPageBySlugQuery(slug, lang)).pipe(
      map((response: any) => {
        let result = { ...response?.allPages[0] };
        result = this.helper.mapKeystoneFields(result);
        return result;
      })
    );
  }
  getVerifyContent(slug: string) {
    if (!this.lang) this.lang = 'en';
    return this.http.post(`${this.adminUrl}`, this.query.getVerificationBySlug(slug, this.lang)).pipe(
      map((response: any) => {
        let result = {
          ...response?.allVerifications[0],
        };
        result = this.helper.mapKeystoneFields(result);
        return result;
      })
    );
  }
  topFaq() {
    if (!this.lang) this.lang = 'en';
    return this.http.post(`${this.adminUrl}/faqgroups/${this.lang}`, this.query.getTopFaq(this.lang)).pipe(
      map((response: any) => {
        const result: any = [];
        response.allFAQGroups.forEach((element: any) => {
          if (element.FAQs.length > 0) {
            const FAQs: any = [];
            element.FAQs.forEach((el: any) => {
              FAQs.push(this.helper.mapKeystoneFields(el));
            });
            element.FAQs = FAQs;
          }
          result.push(this.helper.mapKeystoneFields(element));
        });
        return result;
      })
    );
  }
  public getFaqBySlug(slug: string) {
    this.lang = this.lang || 'en';
    return this.http
      .post(`${this.adminUrl}/faqgroups/${this.lang}`, this.query.getFaqBySlugQuery(slug, this.lang))
      .pipe(
        map((response: any) =>
          response.allFAQGroups.map((group: any) => ({
            ...this.helper.mapKeystoneFields(group),
            FAQs: group.FAQs.map((faq: any) => this.helper.mapKeystoneFields(faq)),
          }))
        )
      );
  }
  public getAllowedIban(slug: string) {
    return this.http.post(`${this.adminUrl}/allowed-ibans`, this.query.getIBansBySlug(slug)).pipe(
      map((response: any) => {
        return JSON.parse(response?.allAllowedIbans[0]?.AllowedIbans);
      })
    );
  }

  public getPaymentMethods() {
    return this.http
      .post<{ allPaymentMethods: PaymentMethods[] }>(`${this.adminUrl}/payment`, this.query.getAllPaymentMethods())
      .pipe(
        map((response: { allPaymentMethods: PaymentMethods[] }) => {
          const depositMethods: PaymentMethods[] = [];
          const withdrawMethods: PaymentMethods[] = [];
          response.allPaymentMethods.forEach((payment: PaymentMethods) => {
            const paymentCountries = JSON.parse(payment?.country);
            if (!paymentCountries || paymentCountries.includes(this.country)) {
              if (payment.depositMethod) depositMethods.push(payment);
              if (payment.withdrawMethod) withdrawMethods.push(payment);
            }
          });
          return {
            depositMethods: depositMethods,
            withdrawMethods: withdrawMethods,
          };
        })
      );
  }

  getGeoCurrenciesBySlug(slug: string): Observable<GeoCurrency | null> {
    return this.http
      .post<ResponseGeoCurrency>(`${this.adminUrl}/geo-currencies`, this.query.getGeoCurrenciesBySlug(slug))
      .pipe(
        map((response: ResponseGeoCurrency) => {
          if (response.allGeoCurrencies.length > 0) {
            const geoCurrency = response.allGeoCurrencies[0];
            return {
              excludeCurrencies: geoCurrency.excludeCurrencies
                ? geoCurrency.excludeCurrencies.split(',').map((item) => item.trim())
                : [],
              priorCurrencies: geoCurrency.priorCurrencies
                ? geoCurrency.priorCurrencies.split(',').map((item) => item.trim())
                : [],
              slug: geoCurrency.slug,
            } as GeoCurrency;
          }
          return null;
        })
      );
  }

  ngOnDestroy() {
    this.$unsubus.next(true);
    this.$unsubus.complete();
  }
  checkDocumentSlug(slug: string) {
    const keywords = ['amlPolicy', 'aff_terms', 'bonus', 'fairplay', 'privacy', 'resp', 'terms'];
    return keywords.some((keyword) => slug.includes(keyword)) ? 'en' : this.helper.getCookie('sitelang');
  }
}
